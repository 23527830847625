<template>
<header class="mb-auto">
</header>

<main class="px-3">
  <h1>{{ msg }}</h1>
</main>

<footer class="mt-auto text-white-50">
</footer>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
