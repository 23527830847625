<template>
  <Demoscene msg="Coming Soon"/>
</template>

<script>
import Demoscene from './components/Demoscene.vue'

export default {
  name: 'App',
  components: {
    Demoscene
  }
}
</script>

<style>
</style>
